import * as React from 'react';
import { FC, useState } from 'react';
// import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import SubMenu from './SubMenu';
import products from '../products';
import categories from '../categories';

import CatalogIcon from '@material-ui/icons/Collections';

// import CatalogIcon from '@material-ui/icons/FormatAlignJustify';

import { useMediaQuery, Theme, Box } from '@material-ui/core';
import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
} from 'react-admin';


type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu: FC<MenuProps> = ({ onMenuClick, logout, dense = false }) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
    });

    const translate = useTranslate();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    
    return (
        <Box mt={1}>
            {' '}
            <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                sidebarIsOpen={true}
                name="pos.menu.catalog"
                icon={<CatalogIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/nomenclature`}
                    primaryText={translate(`resources.products.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<products.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={true}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/category`}
                    primaryText={translate(`resources.categories.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<categories.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={true}
                    dense={dense}
                />
               
            </SubMenu>
        </Box>
    );
};

export default Menu;
