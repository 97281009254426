import React,{useEffect} from 'react';
import AdminPanel from './AdminPanel';
import axios from 'axios';


function App() {
  useEffect(()=>{
    const userToket = localStorage.getItem('userToken');
    // console.log('[APP] user token',userToket);
    if(userToket){
      axios.defaults.headers.common['Authorization'] = `Bearer ${userToket}`;
    }
  },[]);

  return (
      <div className="App">
        <AdminPanel />
      </div>
  );
}

export default App;
