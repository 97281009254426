import { IPosition } from '../types';
import { Action, action} from "easy-peasy";

export interface IContextMenuModel {
    isVisible:boolean,
    position:IPosition,
    categoryFilterId:string,
    setPosition:Action<IContextMenuModel,IPosition>,
    setIsVisible:Action<IContextMenuModel,boolean>,
    setCategoryFilterId:Action<IContextMenuModel,string>,
}

const contextMenuModel:IContextMenuModel = {
    isVisible:false,
    position:{
        x: '0',
        y: '0'
    },
    categoryFilterId:'1',
    setPosition:action((state, position)=>{
        state.position = {...position};
    }),
    setIsVisible:action((state, isVisible)=>{
        state.isVisible = isVisible;
    }),
    setCategoryFilterId:action((state, categoryFilterId)=>{
        state.categoryFilterId = categoryFilterId;
    })

};

export default contextMenuModel;