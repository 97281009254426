// import ProductIcon from '@material-ui/icons/Collections';
// import ProductIcon from '@material-ui/icons/Collections';
import ProductIcon from '@material-ui/icons/FormatListBulleted';

import ProductList from './ProductList';
import ProducCreate from './ProductCreate';
import ProductEdit from './ProductEdit';

export default {
    list: ProductList,
    create: ProducCreate,
    edit: ProductEdit,
    icon:ProductIcon
}