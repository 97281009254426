import { Action, action} from "easy-peasy";

export interface IProductModel {
    listFilterId:number,
    setProductListFilterId:Action<IProductModel,number>,
    setCategoryId:Action<IProductModel,number | string>,
    categoryId:number | string,
}

const productModel:IProductModel = {
    listFilterId:1,
    categoryId:1,
    setProductListFilterId:action((state, listFilterId)=>{
        state.listFilterId = listFilterId;
    }),
    setCategoryId:action((state, categoryId)=>{
        state.categoryId = categoryId;
    })
};

export default productModel;