import React,{useEffect} from 'react';
import { Box } from '@material-ui/core';
import {Create,SimpleForm,TextInput,ListProps,ReferenceInput,SelectInput ,required,CreateProps    } from 'react-admin';
import CategorySelect from './CategorySelect';
import { useStoreActions, useStoreState } from '../store';
import ProductCategorySelect from './ProductCategoryTree';

export default function ProductCreate (props:CreateProps){

  const categoryLastChildrenList = useStoreState(state => state.category.lastChildrenList);
  const productCategoryId = useStoreState(state => state.product.categoryId);
  const setProductCategoryId = useStoreActions(actions =>actions.product.setCategoryId);

  useEffect(()=>{
    setProductCategoryId(1);
  },[]);

    return (
      <>
      <Box display="flex">
          <ProductCategorySelect />
      <Create 
        title='Product Create' 
        {...props}
        record={{category:productCategoryId}} 
      >
        
          <SimpleForm display = "flex">
            
            <TextInput source="name" />
            <TextInput source="sku" />
            {/* <TextInput 
              source="category" 
            /> */}
          </SimpleForm>
          
      </Create>
      </Box>
      </>
    );
};