import * as React from 'react';
// import { useSelector } from 'react-redux';
import { Layout, LayoutProps, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
// import { darkTheme, lightTheme } from './themes';
// import { AppState } from '../types';

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;


export default (props: LayoutProps) => {
    // const theme = useSelector((state: AppState) =>
    //     state.theme === 'dark' ? darkTheme : lightTheme
    // );
    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            menu={Menu}
            // theme={theme}
        />
    //     <div className={classes.root}>
    //     <div className={classes.appFrame}>
    //         <AppBar title={title} open={open} logout={logout} />
    //         <main className={classes.contentWithSidebar}>
    //             <Sidebar>
    //                 <Menu logout={logout} hasDashboard={!!dashboard} />
    //             </Sidebar>
    //             <div className={classes.content}>
    //                 {children}
    //             </div>
    //         </main>
    //         <Notification />
    //     </div>
    // </div>
    );
};
