import React,{useEffect,useState} from 'react';
import { Box, Chip, useMediaQuery, Theme } from '@material-ui/core';
import {List,Datagrid,TextField,ListProps,EditButton,DeleteButton,ArrayField,DateField,UrlField,ListContext } from 'react-admin';
import Aside from './Aside';
import {useStoreState} from '../store';


export default function CategoryList (props:ListProps){
  
    const filterId = useStoreState(state => state.category.filterId);
    // console.log('filterId',filterId);

    // console.log('CategoryList Start');
    const [categoryId,setCategoryId] = useState<number>(1);

    useEffect(()=>{
        console.log('CategoryList mount');
        return ()=>{
          console.log('CategoryList unmount');
        };
      },[]);   
    const isSmall = false;
    
    return (
        <>
        <Box display="flex">
        
           <Aside  />
        
           <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
           <List 
            title='Categories' {...props}
            filter={{id:filterId}}
           >
                <Datagrid>
                    <TextField source='id' />
                    <TextField source='name' />
                    <EditButton basePath='category' />
                    {/* <DeleteButton basePath='category' />  */}
                </Datagrid>
                   
               </List>
               {/* <Pagination rowsPerPageOptions={[10, 20, 40]} /> */}
           </Box>
        </Box>
        </>
       
    );
};