import './ContextMenu.css';
import {useStoreActions,useStoreState} from '../store';


interface ContextMenuProps{
  parentComponent:string,
}

function ContextMenu({parentComponent}:ContextMenuProps) {
  // console.log('ContextMenu start');
  const setCategoryFilterId = useStoreActions(actions => actions.category.setFilterId);
  const setProductListFilterId = useStoreActions(actions => actions.product.setProductListFilterId);
  const setIsVisible = useStoreActions(actions => actions.contextMenu.setIsVisible);
  const position = useStoreState(state => state.contextMenu.position);
  const categoryFilterId = useStoreState(state => state.contextMenu.categoryFilterId);
  const isVisible = useStoreState(state => state.contextMenu.isVisible);


  const display = isVisible?'block':'none';
  const style = {
    display:display,
    left: `${position.x}px`,
    top:`${position.y}px`,
  };

  const handleClick = (e:any,id:string) =>{
    // console.log('FilterListById id = ',id)
    if(e.target.className.includes("CategoryList")){
      // get categories from db
      setCategoryFilterId(parseInt(id));
     
      // getListById(id);
    }else{
      setProductListFilterId(parseInt(id));
    }
    setIsVisible(false);
  };



  return (
    <div 
      className="ContextMenu"
      style={style}
    >
        <div 
          // className="ContextMenu_row"
          className={`ContextMenu_row ${parentComponent}`}
          onClick={(e)=>{handleClick(e,categoryFilterId)}}
        >
          {parentComponent === "CategoryList"?'Get all categories':'Get all products'}
        </div>
    </div>
  );
}

export default ContextMenu;
