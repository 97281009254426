import React,{useState,useEffect} from 'react';
import { Box   } from '@material-ui/core';
import ProductCategoryTree from './ProductCategoryTree';
import {SimpleForm,EditProps,ReferenceInput,SelectInput ,required ,TextInput,ListProps   } from 'react-admin';
import { useStoreActions, useStoreState } from '../store';

const ProductEditChild = (props:any) => {
    
    const productCategories = props.record?.categories?.map((category:any)=>category.id.toString());
    const productCategoryId = useStoreState(state => state.product.categoryId);
    // const setProductCategoryId = useStoreActions(actions =>actions.product.setCategoryId);

   
    

    useEffect(()=>{
       
        return ()=>{
          console.log('ProductEdit unmount');
        };
      },[]);


      console.log('ProductEdit CHILD props = ',props);
    return (
        <>
        {/* {productCategoryId} */}
        {productCategories?
                <Box display="flex" >
                    <ProductCategoryTree 
                        productCategories={productCategories}
                    />

                    <SimpleForm 
                        {...props}
                        record={{
                            ...props.record,
                            category:productCategoryId
                        }}
                    >
                        <TextInput disabled source="id" />
                        <TextInput source="name" />
                        <TextInput source="sku" />
                        {/* <TextInput source="category" value={productCategoryId} /> */}
                    </SimpleForm>
                </Box>:null
            }
            
       </>
    );
};

export default ProductEditChild;
