import {AuthProvider,} from 'react-admin';
import axios,{AxiosError} from 'axios';

interface UserData {
    username:string,
    password:string,
}

const authProvider:AuthProvider = {
    // authentication
    login: async({ username, password }:UserData) =>  {
        console.log('login');
        // localStorage.setItem('isAuth','true');
        // return Promise.resolve();
        try {
            const res = await axios.post('https://appback.familymarket.md/v1/user/login',{ username, password });
            // const res = await axios.post('http://localhost:5000/user/login',{ username, password });
            console.log(res.data.code);
            if(res.data.code === 50001){
                // throw new Error('Login or password incorrect');
                return Promise.reject('Login or password incorrect');
            }else{
                console.log(res.data);
                localStorage.setItem('isAuth','true');
                localStorage.setItem('userToken',res.data.token);
                console.log('token afteer login from storage ', localStorage.getItem('userToken'));
                axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
                return Promise.resolve();
            }   
        } catch (err) {
            console.log(typeof err.response.status);
            if(err.response.status === 401){
                throw new Error('Login or password incorrect');
            }

            if(err.response.status < 200 || err.response.status >= 300){
                throw new Error('Network error');
            }
        }
    },
    checkError: (err) => {
        console.log(err);
        return Promise.resolve()
    },
    checkAuth: () => {
        console.log('Check auth');
        // console.log('get isAuth ',localStorage.getItem('isAuth'));
        if(localStorage.getItem('isAuth')){
            // console.log('get isAuth ',localStorage.getItem('isAuth'));
            return Promise.resolve();
        }
        // localStorage.setItem('isAuth','true')
        // return Promise.resolve();
        console.log('reject');
        return Promise.reject();
    },
    logout: () => {
        if(localStorage.getItem('isAuth')){
            localStorage.removeItem('isAuth');
        }
        localStorage.removeItem('userToken');
        axios.defaults.headers.common['Authorization'] = '';
        return Promise.resolve();
        // return Promise.reject();
    },
    getIdentity: () => Promise.resolve({id:1,name:'username'}),
    // authorization
    getPermissions: () => Promise.resolve(),
};

// async function test (){
//     const s = await authProvider.login({username:'ss',password:'ss'});
//     console.log(s);
// }   
// test();
export default authProvider;