import { Action, action} from "easy-peasy";
import { ICategoryTreeNode } from '../types';

export interface ICategoryModel {
    filterId:number,
    treeData:ICategoryTreeNode | null,
    lastChildrenList:ICategoryTreeNode[],
    setFilterId:Action<ICategoryModel,number>,
    setTreeData:Action<ICategoryModel,ICategoryTreeNode | null>,
    setCategoryLastChildren:Action<ICategoryModel,ICategoryTreeNode[]>,
}

const categoryModel:ICategoryModel = {
    filterId:1,
    treeData:null,
    lastChildrenList:[],
    setFilterId:action((state,payload)=>{
        state.filterId = payload;
    }),
    setTreeData:action((state,treeData)=>{
        state.treeData = treeData;
    }),
    setCategoryLastChildren:action((state,lastChildrenList)=>{
        state.lastChildrenList = [...lastChildrenList];
    }),
};

export default categoryModel;