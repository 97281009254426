import React from 'react';
import {Create,SimpleForm,TextInput,ListProps,ReferenceInput,SelectInput ,required    } from 'react-admin';
import { useStoreActions, useStoreState } from '../store';

export default function CategoryCreate (props:ListProps){

    const categoryLastChildrenList = useStoreState(state => state.category.lastChildrenList);
    const parentId = useStoreState(state => state.category.filterId);
    console.log('categoryLastChildrenList from create',categoryLastChildrenList);

    console.log('CategoryCreate Props = ',props);
    return (
      <>
     {parentId}
      <Create 
        title='Product Create' 
        {...props} 
        record={{parent:parentId}}
      >
         
          <SimpleForm>
          
            <TextInput source="name" />
            {/* <TextInput source="sku" /> */}
          </SimpleForm>
      </Create>
      </>
    );
};