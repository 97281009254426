import React,{useEffect} from 'react';
import { Box } from '@material-ui/core';
import {Edit,SimpleForm,EditProps,ReferenceInput,SelectInput ,required ,TextInput,ListProps   } from 'react-admin';
import { useStoreActions, useStoreState } from '../store';
import ProductCategoryTree from './ProductCategoryTree';
import ProductEditChild from './ProductEditChild';

export default function ProductEdit (props:EditProps){

  const categoryLastChildrenList = useStoreState(state => state.category.lastChildrenList);
  console.log('categoryLastChildrenList from Product edit',categoryLastChildrenList);
  
    useEffect(()=>{
      console.log('ProductEdit  PROPS',props);
      return ()=>{
        console.log('ProductEdit unmount');
      };
    },[]);



    
    return (
      <Box display="flex">
        <Edit 
          title='Product edit'
          {...props}  
        >
          {/* <SimpleForm  >
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <TextInput source="sku" />
                <TextInput source="categories" />
                
                
            </SimpleForm> */}
          
          <ProductEditChild  />
        </Edit>
        </Box>
      
    );
};