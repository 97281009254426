import * as React from 'react';
import {useEffect,useState, FC} from 'react';
import { TreeView,TreeItem } from '@material-ui/lab';
import { Typography, Card, CardContent,makeStyles,Checkbox   } from '@material-ui/core';
import axios from 'axios';
import {PlusSquare,MinusSquare} from '../_customIcons';
import './CategoryTreeCheckbox.css';
import { useStoreActions, useStoreState } from '../store';
import {useCreateContext,useEditContext} from 'react-admin';
import CategoryTreeCheckBox from '../components/CategoryTreeCheckBox';


const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            width: '15em',
            marginRight: '1em',
            overflow: 'initial',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

interface IProductCategorySelect{
    productEditId?:number | string,
}

const ProductCategoryTree  = ({productCategories}:any) => {
  let defaultExpanded = ['1'];
  let categoryLastChildId = 1;
  const setProductCategoryId = useStoreActions(actions =>actions.product.setCategoryId);

  if(productCategories){
    defaultExpanded = productCategories;
    categoryLastChildId = productCategories[productCategories.length - 1];
    // setProductCategoryId(categoryLastChildId);
  }
  // TODO  useMemo

  

  const setCategoryTreeData = useStoreActions(actions => actions.category.setTreeData);
  
  const categoryTreeData = useStoreState(state => state.category.treeData);
  const productCategoryId = useStoreState(state => state.product.categoryId);
  

  const classes = useStyles();

  useEffect(()=>{
    if(productCategories){
      categoryLastChildId = productCategories[productCategories.length - 1];
      setProductCategoryId(categoryLastChildId);
    }
    
    axios.get('https://appback.familymarket.md/v1/category/list')
      .then(res => {
        console.log('CategoryTree Res.data',res.data);
        if(Array.isArray(res.data)){
          // setTreeData({...res.data[0]});
          setCategoryTreeData({...res.data[0]});
        }  
      })
      .catch(err => {
        console.log(err);
      });
    
      
     
      
      return ()=>{
        
        console.log('ProductCategoryTree tree unmount');
        // categoryLastChildrenList = [];
      }
  },[]);

  

  const renderTree = (node:any) => {
    let itemClass = "parent";
    let isLastChild = false;
    let label = node.name;

    if(node.id === 1){
      itemClass = "parent-root";
    }
    if(!node.children){
      isLastChild = true;
      itemClass = "lastChild";

      label = (
        <div>
           <Checkbox 
              checked={productCategoryId == node.id}
              onChange={()=>{setProductCategoryId(node.id)}}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            {/* <FormControlLabel value={node.id} control={<Radio />} label={node.name} /> */}
            
            {/* <CategoryTreeCheckBox
                id={node.id} 
                categoryLastChildId={categoryLastChildId}
            />  */}
            <Typography variant="caption">{node.name}</Typography> 
        </div>
      );
    };

    
      
      return (
        <TreeItem 
            key={node.id} 
            nodeId={node.id.toString()} 
            label={label} 
            data-id={node.id} 
            className={itemClass} 
        >
          {Array.isArray(node.children) ? node.children.map((childNode:any) => renderTree(childNode)) : null}
        </TreeItem>
      );

    // return <></>
  };

    return (
        <>
           <Card className={classes.root}>
            <CardContent>
                <TreeView
                    defaultExpanded={defaultExpanded}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    // defaultEndIcon={}
                    
                >
                    {categoryTreeData !== null? renderTree(categoryTreeData) : null }
                </TreeView>
            </CardContent>
          </Card>
        
        </>
    );
};

export default ProductCategoryTree;
