import React,{useEffect} from 'react';
import { Box, Chip, useMediaQuery, Theme } from '@material-ui/core';
import {List,Datagrid,TextField,ListProps,EditButton,DeleteButton} from 'react-admin';
import Aside from './Aside';
import {useStoreState} from '../store';

export default function ProductList (props:ListProps){
    const productListFilterId = useStoreState(state => state.product.listFilterId);

    useEffect(()=>{
        console.log('ProductList mount');
        return ()=>{
          console.log('ProductList unmount');
        };
      },[]);   

    // console.log(props);
    const isSmall = false;
    console.log('ProductList props',props);
    return (
        <>
             <Box display="flex">
                <Aside />
                <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
                <List 
                    title='Products' {...props}
                    filter={{id:productListFilterId}}
                >
                    <Datagrid>
                            <TextField source='id' />
                            <TextField source='name' />
                            <TextField source='sku' />
                            <EditButton basePath='nomenclature' />
                            {/* <DeleteButton basePath='nomenclature' /> */}
                    </Datagrid>
                </List>
                    {/* <Pagination rowsPerPageOptions={[10, 20, 40]} /> */}
                </Box>
            </Box>
        </>
       
    );
};