import React from 'react';
import {Edit,SimpleForm,ListProps,ReferenceInput,SelectInput ,required ,TextInput   } from 'react-admin';
import CategoryParentTree from './CategoryParentTree';

export default function CategoryEdit (props:ListProps){
  

    console.log('CategoryEdit',props);
    return (
      <Edit 
        title='Product edit' 
        {...props}  
      >
        {/* <CategoryParentTree /> */}
          <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" />
           
          </SimpleForm>
      </Edit>
    );
};