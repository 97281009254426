import * as React from 'react';
import { FC } from 'react';
import inflection from 'inflection';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CategoryTree from '../components/CategoryTree';


import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    useGetList,
} from 'react-admin';

import { Category } from '../types';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            width: '15em',
            marginRight: '1em',
            overflow: 'initial',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const Aside = () => {
    

    const { data, ids } = useGetList<Category>(
        'category',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
        {}
    );

    const idss = [1,2,3];

    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                {/* <FilterLiveSearch /> */}

                
                <CategoryTree 
                    parentComponent= 'CategoryList'  
                />
                
            </CardContent>
        </Card>
    );
};

export default Aside;
