import categoryModel,{ICategoryModel} from './categoryModel';
import productModel,{IProductModel} from './productModel';
import contextMenuModel,{IContextMenuModel} from './contextMenuModel';

export interface  IStoreModel {
    category:ICategoryModel,
    product:IProductModel,
    contextMenu:IContextMenuModel,

}

const storeModel:IStoreModel = {
    category:categoryModel,
    contextMenu:contextMenuModel,
    product:productModel
};

export default storeModel;