import React,{useEffect} from 'react';
import { Admin, Resource } from 'react-admin';
import dataProvider from './dataProvider';
import products from './products';
import categories from './categories';
import authProvider from './authProvider';
import { Layout } from './layout';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');


const apiUrl = 'https://appback.familymarket.md/v1';

export default function AdminPanel (){
    return (
        <Admin 
            authProvider={authProvider}
            dataProvider={dataProvider(apiUrl)}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource 
                name="nomenclature"  
                // name="nomenclature" 
                {...products}
                options={{ label: 'Products' }}
            />
            <Resource 
                name="category" 
                {...categories}
                options={{ label: 'Categories' }}
            />
          
        </Admin>
    );
};