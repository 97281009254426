import * as React from 'react';
import {useEffect,useState} from 'react';
import { FC } from 'react';
import { TreeView,TreeItem } from '@material-ui/lab';
import axios from 'axios';
import ContextMenu from './ContextMenu'
import {PlusSquare,MinusSquare,CloseSquare} from '../_customIcons';
import { useStoreActions, useStoreState } from '../store';
import { ICategoryTreeNode } from '../types';



interface CategoryTreeProps{
  parentComponent:string
}

const CategoryTree:FC <CategoryTreeProps> = ({parentComponent}) => {
  const [expanded,setExpanded] = useState(['1']);
  const setCategoryTreeData = useStoreActions(actions => actions.category.setTreeData);
  const categoryTreeData = useStoreState(state => state.category.treeData);
  const categoryFilterId = useStoreState(state => state.category.filterId);
  
  const setContextMenuPosition = useStoreActions(actions => actions.contextMenu.setPosition);
  const setIsContextMenuVisible = useStoreActions(actions => actions.contextMenu.setIsVisible);
  const setContextMenuCategoryFilterId = useStoreActions(actions => actions.contextMenu.setCategoryFilterId);

  

  useEffect(()=>{
    console.log('category tree mount');
    
    axios.get('https://appback.familymarket.md/v1/category/list')
      .then(res => {
        console.log('CategoryTree Res.data',res.data);
        if(Array.isArray(res.data)){
          
          setCategoryTreeData({...res.data[0]});
        }  
      })
      .catch(err => {
        console.log(err);
      });

      const onMouseDownEvent = (e:any)=>{
        const parentElementOfTreeItem = e.target.parentNode.parentNode;
        // console.log(e.target.className);

        if(typeof e.target.className === 'string'){
          if(!parentElementOfTreeItem.className.includes("parent") && 
          !parentElementOfTreeItem.className.includes("lastChild") && 
          !e.target.className.includes("ContextMenu_row")){
              setIsContextMenuVisible(false);
              // console.log('mousedown');
          }
        }else{
          setIsContextMenuVisible(false);
        }

      }

      document.addEventListener('mousedown',onMouseDownEvent);

      
      

      return ()=>{
        document.removeEventListener('mousedown',onMouseDownEvent);
        console.log('Category tree unmount');
       
      }
  },[]);

  useEffect(()=>{
    const treeRoot = document.querySelector('ul[role="tree"]');
    // console.log("treeRoot",treeRoot);
    if(treeRoot !== null && categoryTreeData !== null){
      // console.log('useeFFECT TREE DATA');
      treeRoot.addEventListener('contextmenu',openContextMenu);
    }
    
  // },[treeData]);
  },[categoryTreeData]);

  // useEffect(()=>{
  //   console.log('setEXPANDEDDDDDDDDDDDDDDDDDDDDDD');
  //   setExpanded([...expanded,categoryFilterId.toString()]);
  //   console.log(expanded);
  // },[categoryFilterId]);


  const openContextMenu  = (e:any) => {
    const parentElement = e.target?.parentNode?.parentNode;
    const parentRect = parentElement.getBoundingClientRect();

    const x = parentRect.left + 20;
    const y = parentRect.top + 20;

    const menuPosition = {x ,y};
    console.log('parentElement typeof ',typeof parentElement.getAttribute('data-id'));
    // console.log('parentElement',parentElement.id);

    if(parentComponent === 'CategoryList'){
      if(!parentElement.className.includes("lastChild")){
        // console.log('Element Name  = ',e.target.innerHTML);
        setContextMenuCategoryFilterId(parentElement.getAttribute('data-id'));
        setIsContextMenuVisible(true);
        setContextMenuPosition({...menuPosition});
        e.preventDefault();
      }
    }else if(parentComponent === 'ProductList'){
      setContextMenuCategoryFilterId(parentElement.getAttribute('data-id'));
      // console.log('ProductList');
      setIsContextMenuVisible(true);
      setContextMenuPosition({...menuPosition});
      e.preventDefault();
    }
  }

  const renderTree = (node:any) => {
    let itemClass = "parent";
    let isLastChild = false;
    if(node.id === 1){
      itemClass = "parent-root";
    }
    if(!node.children){
      isLastChild = true;
      itemClass = "lastChild";
    };
      
      return (
        <TreeItem key={node.id} nodeId={node.id.toString()} label={node.name} data-id={node.id} className={itemClass} >
          {Array.isArray(node.children) ? node.children.map((childNode:any) => renderTree(childNode)) : null}
        </TreeItem>
      );

    // return <></>
  };

    return (
        <>
        {/* {categoryFilterId} */}
            <TreeView
                // defaultExpanded={['root']}
                defaultExpanded={['1']}
                // expanded={expanded}
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
            >

                {/* {renderTree(data)} */}
                {/* {treeData !== null? renderTree(treeData) : null } */}
                {categoryTreeData !== null? renderTree(categoryTreeData) : null }
            </TreeView>
            <ContextMenu 
              parentComponent={parentComponent}
            />
        </>
    );
};

export default CategoryTree;