import { fetchUtils, DataProvider } from 'ra-core';

import axios from 'axios';


export default (
    apiUrl: string,
    httpClient = fetchUtils.fetchJson,
    countHeader: string = 'Content-Range'
): DataProvider => ({
    getList: (resource, params) => {
      const apiUrn = `${resource}/list`;

      // if(resource === 'category'){
      if(params.filter.id){
        console.log(`[POST] ${apiUrl}/${apiUrn} id= ${params.filter.id}`);
        console.log(params);
        return axios.post(`${apiUrl}/${apiUrn}`,{...params.filter}).then(res =>{
          console.log(`Response data for  ${resource} list = `,res.data);
            return {
               data:res.data,
               total:5
            }
        })
      }
      console.log(`[GET] ${apiUrl}/${apiUrn}`);
      console.log(params);

      return axios.get(`${apiUrl}/${apiUrn}`).then(res =>{
          console.log(`Response data for  ${resource} list = `,res.data);
            return {
               data:res.data,
               total:5
            }
      })
    },

    getOne: (resource, params) =>{
        console.log('[GET ONE] URL = ',`${apiUrl}/${resource}/one`);
       
        // return axios.get(`${apiUrl}/${resource}/${params.id}`).then(res =>{
        const postData = {id:params.id};
        console.log(postData);
        return axios.post(`${apiUrl}/${resource}/one`,postData).then(res =>{
            console.log('Res data get one',res.data);
            return {
               data:res.data,
            }
      })
    },
    getMany: (resource, params) => {
        console.log(`[GET MANY] ${apiUrl}/${resource}`);
        console.log('resource = ',resource);
        console.log(params);
        return axios.post(`${apiUrl}/${resource}/list`,{id:params.ids[0]}).then(res =>{
          console.log(res.data);
            return {
               data:res.data,
               total:5
            }
      })
    },

    getManyReference: (resource, params) => {
        console.log('getManyReference');
        console.log('resource = ',resource);
        console.log(params);
        return axios.post(`${apiUrl}/${resource}`).then(res =>{
            return {
               data:res.data,
               total:5
            }
      })
    },

    update: (resource, params) =>{
        console.log(`[UPDATE] ${apiUrl}/${resource} `,params.data);
       
        return axios.put(`${apiUrl}/${resource}/update`,{...params.data}).then(res =>{
            return {
               data:res.data
            }
      })
    },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>{
        console.log('updateMany');
        console.log('resource = ',resource);
        console.log(params);
        return axios.post(`${apiUrl}/${resource}`).then(res =>{
          
            return {
               data:res.data,
               total:5
            }
      })
    },
    create: async(resource, params) =>{
        // if(params.data.category == 1){
        //   throw new Error('asdasd');
        // }
        console.log('[CREATE] URL = ',`${apiUrl}/${resource}/create`);
        console.log('resource = ',resource);
        console.log(params);
        return axios.post(`${apiUrl}/${resource}/create`,{...params.data})
          .then(res =>{
            console.log('response data = ', res.data);
            return {
              data:res.data,
              //  data:{id:1,name:'test category'},
            }
          })

    },
    delete: (resource, params) =>{
        console.log('delete');
        console.log('resource = ',resource);
        console.log(params);
        return axios.post(`${apiUrl}/${resource}`).then(res =>{
            return {
               data:res.data,
               total:5
            }
      })
    },
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>{
        console.log('deleteMany');
        console.log('resource = ',resource);
        console.log(params);
        return axios.post(`${apiUrl}/${resource}`).then(res =>{
            return {
               data:res.data,
               total:5
            }
      })
    }
});
